import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BRANCHINGMP4 from "../assets/branchingwithcode.mp4"
import TITLECHANGEMP4 from "../assets/titleChange.mp4"

const BranchingPage = () => (
  <Layout>
    <SEO title="Branching" />
    <h1>Branching</h1>
    <p>This page is all about branching.</p>
    <h2>Here is Josh making a branch and changing the color of the 'Hi People!' to green using Microsoft VS Code.</h2>
    <p><video controls width="920">
      <source src={BRANCHINGMP4} type="video/mp4" />
    </video></p>
    <h2>Here Josh is making another branch and changing the title.</h2>
    <p>This time we have sound worked out, so Josh explains things as he goes along.</p>
    <p>We'll start by cloning the repo fresh, and then running yarn inside the top directory of the repo.</p>
    <p>This is equivalent to running "npm i", but yarn is able to use workspaces".  Which we will need later on when working with our theme.</p>
    <p> </p>
    <p> </p>
    <p><video controls width="920">
      <source src={TITLECHANGEMP4} type="video/mp4" />
    </video></p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default BranchingPage
